import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout/layout'
import Header from '../../components/header/header'
import Nav from '../../components/nav/nav'
import Post from '../../components/post'
import Footer from '../../components/footer/footer'

export default ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout>
      <Header />
      <Nav />
      <main>
        {
          posts.map(item => {
            const { frontmatter, html, id } = item.node
            const src = frontmatter.imageSrc ? frontmatter.imageSrc.childImageSharp.original.src : null
            return (
              <Post
                src={src}
                date={frontmatter.date}
                title={frontmatter.title}
                imageAlt={frontmatter.imageAlt}
                imageTitle={frontmatter.imageTitle}
                imageCaption={frontmatter.imageCaption}
                key={id}
              >
                {
                  html && <div dangerouslySetInnerHTML={{__html: item.node.html}} />
                }
              </Post>
            )
          })
        }
      </main>
      <Footer />
    </Layout>
  )
}

export const query = graphql `
  query {
    allMarkdownRemark(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          id
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            imageAlt
            imageTitle
            imageCaption
            imageSrc {
              childImageSharp {
                original {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
