import React from 'react'
import styles from './post.module.css'

const ImageWithCaption = (props) => (
  <figure className={styles.imageWithCaption}>
    <a href={props.src}>
      <img
        src={props.src}
        alt={props.alt}
      />
    </a>
    {props.title && <p className={styles.imageTitle}>{props.title}</p>}
    {props.caption && <figcaption className={styles.imageCaption}>{props.caption}</figcaption>}
  </figure>
)

export default (props) => {
  return (
    <article className={styles.post}>
      <header className={styles.postHeader}>
        <p className={styles.postDate}>{props.date}</p>
        <h2 className={styles.postTitle}>{props.title}</h2>
      </header>
      {
        props.src
        ? <ImageWithCaption
            src={props.src}
            alt={props.imageAlt}
            title={props.imageTitle}
            caption={props.imageCaption} />
        : null
      }
      {props.children}
    </article>
  )
}
